<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">

          <a-col :md="6" :sm="8">
            <a-form-item label="订单编号">
              <a-input placeholder="请输入订单编号" v-model="queryParam.id"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
<!--            <a-form-item label="订单状态">
              <a-select placeholder="请选择订单状态" style="width: 100%" v-model="queryParam.orderStatus">
                <a-select-option v-for="(item, index) in this.customDict.OrderStatusEnum" :value="item.type"
                                 :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>-->
            <a-form-item label="订单状态">
              <a-select placeholder="请选择订单状态" v-model="queryParam.orderStatus">
                <a-select-option value="2110"> 待付款</a-select-option>
                <a-select-option value="2120"> 待验券</a-select-option>
                <a-select-option value="2130"> 待评价</a-select-option>
                <a-select-option value="2131"> 已完成</a-select-option>
                <a-select-option value="2141"> 退款申请中</a-select-option>
                <a-select-option value="2140"> 已退款</a-select-option>
                <a-select-option value="2150"> 已取消</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="店铺名称">
              <a-input placeholder="请输入店铺名称" v-model="queryParam.shopName"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="剧本名称">
              <a-input placeholder="请输入剧本名称" v-model="queryParam.name"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="用户手机号">
              <a-input placeholder="请输入用户手机号" v-model="queryParam.userMobile"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item label="用户名称">
              <a-input placeholder="请输入用户名称" v-model="queryParam.nickName"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleQuery(1)" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <!--<a-button type="primary" icon="download" @click="handleExportXls('订单')">导出</a-button>-->
    </div>

    <a-tabs v-model="activeKey" @tabClick="changeTab">
      <a-tab-pane key="99" tab="全部订单"/>
      <a-tab-pane key="2110" tab="待付款的订单"/>
      <a-tab-pane key="2120" tab="待验券订单"/>
      <a-tab-pane key="2130" tab="待评价的订单"/>
      <a-tab-pane key="2131" tab="已完成的订单"/>
      <a-tab-pane key="2141" tab="退款申请中的订单"/>
      <a-tab-pane key="2140" tab="已退款的订单"/>
      <a-tab-pane key="2150" tab="已取消的订单"/>
      <!--      <a-tab-pane key="98" tab="三个月前订单"/>-->
    </a-tabs>

    <!-- table区域-begin -->
    <div style="overflow:auto">

      <a-pagination
        v-if="dataSource.length>0"
        simple
        style="float: right;margin-bottom: 16px;"
        :current="ipagination.current"
        :pageSize="ipagination.pageSize"
        :total="ipagination.total"
        :show-total="ipagination.showTotal"
        size="small"
        @change="changeTablePage"
        @showSizeChange="showSizeChange"
      />
      <a-spin :spinning="loading">
        <table class="order-table order-table-hader">
          <colgroup>
            <col class="table-mod__col1">
            <col class="table-mod__col2">
            <col class="table-mod__col3">
            <col class="table-mod__col4">
            <col class="table-mod__col5">
            <col class="table-mod__col6">
            <col class="table-mod__col7">
            <col class="table-mod__col8">
          </colgroup>
          <tbody>
          <tr>
            <td>宝贝</td>
            <td>单价</td>
            <td>数量</td>
            <td>实收款</td>
            <td>用户名称</td>
            <td>订单状态</td>
            <td>联系电话</td>
            <td>玩本时间</td>
          </tr>
          </tbody>
        </table>
        <div v-if="dataSource.length>0" class="order-table-item" v-for="(order,index) in dataSource" :key="index">
          <p class="order-goods-hader">
            <span>订单号:</span>
            <span>{{ order.id }}</span>
            <span style="margin-left: 50px;">创建时间:</span>
            <span>{{ order.createTime }}</span>
          </p>
          <table class="order-table order-table-body">
            <colgroup>
              <col class="table-mod__col1">
              <col class="table-mod__col2">
              <col class="table-mod__col3">
              <col class="table-mod__col4">
              <col class="table-mod__col5">
              <col class="table-mod__col6">
              <col class="table-mod__col7">
              <col class="table-mod__col8">
            </colgroup>
            <tbody>
            <tr>
              <div style="float: left;margin-left: 10px;width: 73%;color: #3c3c3c;">
                <div style="float: left;">
                  <img v-if="order.cover" :src="order.cover" style="width:60px;height:62px;"/>
                </div>
                <div style="width: 100%;text-align: left;font-size: 14px;line-height: 16px;">
                  <template>店铺名称:&nbsp;&nbsp;{{ order.shopName }}</template>
                </div>
<!--                <div style="width: 100%;text-align: left;font-size: 14px;line-height: 16px;">
                  <template>店铺编号:&nbsp;&nbsp;{{ order.shopId }}</template>
                </div>-->
                <br>
                <div style="width: 100%;text-align: left;font-size: 14px;line-height: 16px;">
                  <template>剧本名称:&nbsp;&nbsp;{{ order.name }}</template>
                </div>
<!--                <div style="width: 100%;text-align: left;font-size: 14px;line-height: 16px;">
                  <template>剧本编号:{{ order.scriptId }}</template>
                </div>-->
              </div>
              <td class="no-boder-right">￥{{ order.payPrice }}</td>
              <td class="no-boder-right">{{ order.orderCount }}</td>
              <td class="no-boder-right">￥{{ order.payAmount }}</td>
              <td class="no-boder-right">{{ order.nickName }}</td>
              <td class="no-boder-right">
                <custom-dict-tag :options="customDict.OrderStatusEnum" :value="order.orderStatus"/>
                <p>
                  <a @click="handleInfo(order.id)">详情</a>
                </p>
              </td>
              <td class="no-boder-right">{{ order.userMobile }}</td>
              <td class="no-boder-right">{{ order.playTime }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </a-spin>

      <a-empty v-if="dataSource.length==0&&!loading" :image="simpleImage"/>
    </div>

    <a-pagination
      v-if="dataSource.length>0"
      style="float: right;margin: 16px 0;"
      :current="ipagination.current"
      :pageSize="ipagination.pageSize"
      :total="ipagination.total"
      :show-total="ipagination.showTotal"
      size="small"
      @change="changeTablePage"
      @showSizeChange="showSizeChange"
    />
    <!-- table区域-end -->
    <!-- 表单详情 -->
    <orderInfo-modal ref="modalInfo"></orderInfo-modal>
  </a-card>
</template>

<script>
import {Empty} from 'ant-design-vue';
import OrderInfoModal from './modules/OrderInfoModal'
import pick from 'lodash.pick'
import {getAction, httpAction, putAction} from '@/api/manage'
import Vue from 'vue'
import OrderShippingInfoModal from './modules/OrderShippingInfoModal'
import moment from 'moment'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {listOrderScript} from "@/api/order/orderScript";

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function fake() {
    const keyword = value;
    const dictCode = 'express';
    if (keyword == undefined || keyword == '') {
      return;
    }

  }

  timeout = setTimeout(fake, 300);
}

export default {
  name: "OrderList",
  components: {
    OrderInfoModal,
    OrderShippingInfoModal,
    CustomDictTag
  },
  data() {
    return {
      description: '订单管理页面',
      simpleImage: "",
      loading: false,

      /* 数据源 */
      dataSource: [],
      /* 分页参数 */
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条"
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      // 表头
      visible: false,
      data: [],
      value: undefined,
      form: this.$form.createForm(this),
      confirmLoading: false,
      visibleRemark: false,
      model: {},
      formRemark: this.$form.createForm(this),
      confirmLoadingRemark: false,
      activeKey: '99',//被选中的tab
      /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
      queryParam: {
        startCreateTime: moment().subtract(3, "months").format("YYYY-MM-DD"),
        endCreateTime: moment().format("YYYY-MM-DD")

      },
      currentSelectShopName: "",
    }
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict']),
  },
  methods: {
    /** 查询订单-预约打本订单列表 */
    getList() {
      this.loading = true
      listOrderScript(this.queryParam).then(response => {
        this.dataSource = response.data.records;
        this.ipagination.total = response.data.total;
        this.loading = false
      })
    },

    handleInfo: function (id) {
      this.$refs.modalInfo.info(id);
      this.$refs.modalInfo.title = "订单详情";
    },
    getExpressNo(record) {
      this.visible = true;
      this.form.resetFields();
      this.orderId = record.id;
    },
    handleCancel() {
      this.visible = false;
    },
    handleSearch(value) {
      fetch(value, data => (this.data = data));
    },
    handleChange(value) {
      this.value = value;
      fetch(value, data => (this.data = data));
    },

    handleCancelRemark() {
      this.close();
    },
    close() {
      this.$emit('close');
      this.visibleRemark = false;
    },

    //切换tab
    changeTab(index) {
      this.queryParam = {};
      var startCreateTime, endCreateTime;
      if (index == '99' || index == '98') {
        if (index == '99') {
          startCreateTime = moment().subtract(3, "months").format("YYYY-MM-DD") + ' 00:00:00';
          endCreateTime = moment().format("YYYY-MM-DD") + ' 23:59:59';
        }
        if (index == '98') {
          startCreateTime = '';
          endCreateTime = moment().subtract(3, "months").format("YYYY-MM-DD") + ' 23:59:59';
        }
        index = undefined;
      } else {
        startCreateTime = '';
        endCreateTime = '';
      }
      this.queryParam.startCreateTime = startCreateTime;
      this.queryParam.endCreateTime = endCreateTime;
      this.queryParam.pageNum = this.ipagination.current;
      this.queryParam.pageSize = this.ipagination.pageSize;
      this.queryParam.orderStatus = index;
      this.getList(1);
    },
    updateOrderStatus(v) {
      this.queryParam.startCreateTime = "";
      this.queryParam.endCreateTime = "";
      this.queryParam.orderStatus = v;
      this.activeKey = v;
      this.loadData(1);
    },
    disabledStartDate(startValue) {
      const endValue = this.queryParam.pEndCreateTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.queryParam.pStartCreateTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    //页码改变的回调
    changeTablePage(page, pageSize) {
      this.ipagination.current = page;
      this.ipagination.pageSize = pageSize;
      this.loadData();
    },
    //ageSize 变化的回调
    showSizeChange(current, size) {
      this.ipagination.current = 1;
      this.ipagination.pageSize = size;
      this.loadData();
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },


    //加载列表数据
    loadData(arg) {
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1;
      }
      var params = this.queryParam;//查询条件
      this.queryParam.pageNum = this.ipagination.current;
      this.queryParam.pageSize = this.ipagination.pageSize;
      this.getList(1);
      // var currentShopId = Vue.ls.get("currentShopId");
      // if (!params['shopId'] && currentShopId) {
      //   params['shopId'] = currentShopId;
      // }
      // params['pageNo'] = this.ipagination.current;
      // params['pageSize'] = this.ipagination.pageSize;
      // this.loading = true;
      // getAction(this.url.list, params).then((res) => {
      //   if (res.success) {
      //     this.dataSource = res.result.records;
      //     this.ipagination.total = res.result.total;
      //   }
      //   if (res.code === 510) {
      //     this.$message.warning(res.message)
      //   }
      //   this.loading = false;
      // })
    },
    //展示物流信息
    orderShippingInfo: function (id) {
      this.$refs.orderShippingInfo.info(id);
      this.$refs.orderShippingInfo.title = "物流详情";
    },
    onClearSelectedShop() {
      this.currentSelectShopName = "";
      this.queryParam.shopId = "";
      this.loadData();
    },
    searchReset() {
      this.queryParam = {}
      this.loadData(1);
    }
  }
}
</script>
<style scoped>
@import '../../../assets/less/common.less';
</style>

<style lang="less" scoped>
.order-table {
  width: 100%;
  min-width: 998px;

  td {
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: top;
  }
}

.order-table-hader {
  background: #F9F9F9;
  border: 1px solid #ECECEC;
  margin-bottom: 5px;
  text-align: center;
}

.table-mod__col1 {
  width: 35% !important;
}

.table-mod__col2, .table-mod__col3, .table-mod__col4, .table-mod__col8 {
  width: 8% !important;
}

.table-mod__col5, .table-mod__col6, .table-mod__col7 {
  width: 11% !important;
}

.order-table-item {
  width: 100%;
  min-width: 998px;
  margin-bottom: 5px;
  border: 1px solid #ECECEC;

  .order-goods-hader {
    padding-left: 30px;
    height: 44px;
    line-height: 44px;
    background: #eaf8ff;
    color: #3c3c3c;
    margin-bottom: 0px;

    span {
      font-size: 13px;
    }
  }
}

.order-table-item:hover {
  border: 1px solid #AED8FF;
}

.order-table-body {

  tr td {
    text-align: center;
    vertical-align: top;
    border-right: 1px solid #ececec;
    border-top: 1px solid #ececec;
  }

  tr td:first-child {
    text-align: left;
  }

  p {
    margin-bottom: 0px;
  }

  .no-boder-right {
    border-right: 0px;
  }

}
</style>